import * as React from 'react';
import { List, Datagrid, TextField, DateField, FunctionField } from 'react-admin';
import { renderLayerCount } from '../../helpers/utils';
import './style.css';

export const ListComponent: React.FC<any> = (props) => {
  return (
    <List {...props} title="Companies" >
      <Datagrid rowClick={'show'}>
        <FunctionField label="Full name" render={(resource: any) => `${resource.owner?.first_name ?? 'No first name'} ${resource.owner?.last_name ?? 'No last name'}`} />
        <TextField label="Company name" source="name" />
        <TextField label="Status" source="status" />
        <DateField label="Created at" source="created_at" />
      </Datagrid>
    </List>
  );
}