import * as React from 'react';
import { forwardRef, memo } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  AppBar,
  Layout,
  Logout, useGetIdentity,
  UserMenu,
  useUserMenu,
} from 'react-admin';
import {
    MenuItem,
    MenuItemProps,
    ListItemIcon,
    CssBaseline,
} from '@mui/material';
import Key from '@mui/icons-material/Key';

const ChangePassword = forwardRef<HTMLLIElement, MenuItemProps>(
    (props, ref) => {
        const { onClose } = useUserMenu();

        return (
            <MenuItem
                ref={ref}
                {...props}
                sx={{ color: 'text.secondary' }}
                onClick={event => {
                    window.location.replace('#/change-password')
                    onClose();
                }}
            >
                <ListItemIcon sx={{ minWidth: 5 }}>
                    <Key />
                </ListItemIcon>
                Change password
            </MenuItem>
        );
    }
);

// const MyUserMenu = () => (
//     <UserMenu label={'test2'}>
//         <ChangePassword />
//         <Logout />
//     </UserMenu>
// );

const MyUserMenu = () => {
  const { data, isLoading } = useGetIdentity(); // Fetch the user identity
  console.log("test",data);

  if (isLoading) {
    return null; // You can show a loader here if necessary
  }

  return (
    <UserMenu>
      <ChangePassword />
      <Logout />
    </UserMenu>
  );
};

const MyAppBar = memo(props => <AppBar {...props} userMenu={<MyUserMenu />} />);

const propsFun = (props: any) => (
    <>
        <CssBaseline />
        <Layout {...props} appBar={MyAppBar} />
        <ReactQueryDevtools
            initialIsOpen={false}
            toggleButtonProps={{ style: { width: 20, height: 30 } }}
        />
    </>
);

export default propsFun;